// import axios from "axios";
// import { ToastProgrammatic as Toast } from "buefy";
// import APP_CONFIG from "@/apps/core/modules/config.js";
// import { has } from "lodash";
import ListModelBase from "@/apps/core/models/listModelBase.js";


const modelName = "kegiatanList";
const modelPath = "/aktivitas/kegiatan-pspd/";

class KegiatanPSPDList extends ListModelBase {
  constructor() {
    let filterMap = { sub_departemen: null, stase: null, cari: "", jenis: null }
    super(modelName, modelPath, filterMap);
  }

  // initObservables() {
  //   super.initObservables();
  //   this.observables.errorList = [];
  //   this.observables.kegiatanBelumSet = {};
  //   // this.observables.cloneList = [];
  // }

  // validateInline(idx) {
  //   let errorMap = this.observables.errorList[idx];
  //   if (this.observables.kegiatanList[idx].nilai_sikap == null) {
  //     if (has(errorMap, "nilai_sikap")) {
  //       errorMap = "";
  //     }
  //   }
  // }

  // getLoadData(data) {
  //   this.observables.kegiatanBelumSet = {};
  //   let kegiatanList = super.getLoadData(data);
  //   for (let kegiatan of kegiatanList) {
  //     let nilai_sikap = kegiatan['nilai_sikap'];
  //     kegiatan['nilai_sikap'] = nilai_sikap? parseFloat(nilai_sikap):nilai_sikap;
  //   }

  //   this.observables.errorList = [];
  //   let kegiatanBelumSet = kegiatanList.filter(
  //     d => d.verifikasi == 'Belum' || d.nilai_sikap == null
  //   );

  //   for (let kegiatan of kegiatanBelumSet) {
  //     this.observables.kegiatanBelumSet[kegiatan.id] = {verifikasi: kegiatan.verifikasi, nilai_sikap: kegiatan.nilai_sikap};
  //   }
  //   return kegiatanList;
  // }

  // getPayload() {
  //   let kegiatanSet = this.observables.kegiatanList.filter(
  //     d => has(this.observables.kegiatanBelumSet, d.id) && 
  //     (d.nilai_sikap != null || d.verifikasi != 'Belum')
  //   );
  //   let data = kegiatanSet.length > 0 ? {data_set: {}} : {data_set: null};
  //   for (let kegiatan of kegiatanSet) {
  //     data.data_set[kegiatan.id] = {nilai_sikap: kegiatan.nilai_sikap, verifikasi: kegiatan.verifikasi};
  //   }
  //   return data;
  // }

  // updateErrorFields(respErrorList) {
  //   /* digunakan untuk load error dari response */
  //   this.observables.errorList = respErrorList;
  // }

  // update(onSaved) {
  //   let updateURL = `${APP_CONFIG.baseAPIURL}${modelPath}verifikasi/`;
  //   const data = this.getPayload();
  //   if (data.data_set == null) return;

  //   axios.patch(updateURL, data)
  //     .then((response) => {
  //       Toast.open("Data berhasil disimpan.");
  //       if (onSaved) {
  //         onSaved(response.data);
  //       }
  //     })
  //     .catch((error) => {
  //       if (error.response.status === 400) {  // Perlu handle 403 juga
  //         Toast.open("Data gagal disimpan.");
  //         this.updateErrorFields(error.response.data);
  //       }
  //     });
  // }
}

export default KegiatanPSPDList;